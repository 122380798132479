import {useQuery} from "@tanstack/react-query";
import {Select, Typography} from "antd";
import React, {useState, useEffect} from "react";

import type {WidgetProps} from "@/app/_components/form-builder";
import {UserModule} from "@/modules/user";

export const UserWidget = ({value, disabled, required, onChange}: WidgetProps) => {
  const [options, setOptions] = useState<{value: string; label: string}[]>([]);

  const {data} = useQuery({queryKey: ["users"], queryFn: () => UserModule.fetchUsers()});

  useEffect(() => {
    if (data) {
      const options = data.edges.map(({node: user}) => {
        //label is either the nickname or the name
        let labelText = user.nickName ?? (user.firstName || "") + " " + (user.lastName || "").trim();

        //shouldn't happen since firstName's required constraint, but at least show id if it fails
        if (!labelText) labelText = `id: ${user.id}`;

        return {
          value: user.id,
          label: labelText,
        };
      });
      if (!required) {
        options.unshift({value: "", label: ""});
      }
      setOptions(options);
    }
  }, [data, required]);

  const handleChange = (value: string) => {
    if (value === "") {
      onChange?.(null);
    } else {
      onChange?.(value);
    }
  };

  return !onChange ? (
    <Typography.Text>{value ? value.displayName : ""}</Typography.Text>
  ) : (
    <Select
      value={value === undefined ? "" : value}
      disabled={disabled}
      options={options}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
      onChange={handleChange}
    />
  );
};
