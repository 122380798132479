type Predicate<T> = (value: T, index: number, array: T[]) => boolean;

export function find<T>(array: T[], predicate: Predicate<T>): T | undefined {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (predicate(element, i, array)) {
      return element;
    }
  }
  return undefined;
}
