export const insertIf = <T>(condition: boolean | unknown, ...elements: (T | (() => T))[]) => {
  let isTrue;
  const conditionType = typeof condition;

  if (conditionType === "boolean") isTrue = condition;
  else isTrue = condition !== null && conditionType !== "undefined";

  if (isTrue) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return elements.map((element) => (typeof element === "function" ? element() : element));
  }

  return [];
};
