import {Image as AntdImage, ImageProps} from "antd";

type Props = (ImageProps | (Omit<ImageProps, "src"> & {data: Buffer})) & {round?: true};

function Image(props: Props): JSX.Element {
  const finalProps = {
    ...props,
    style: {
      borderRadius: props.round ? "50%" : undefined,
      ...props.style,
    },
  };
  if (!("data" in props)) {
    return <AntdImage {...finalProps} />;
  }
  const imageBase64 = Buffer.from(props.data).toString("base64");
  return <AntdImage src={`data:image/png;base64,${imageBase64}`} {...finalProps} />;
}

export default Image;
