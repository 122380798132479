"use client";

import {Button, message} from "antd";
import React, {useState, useCallback, useRef} from "react";
import ReactAvatarEditor from "react-avatar-editor";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  button: {
    fontSize: "1rem",
    width: 100,
  },
  smallButton: {
    fontSize: "1rem",
    width: 40,
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    margin: "4px",
  },
  smallControlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    margin: "4px",
  },
});

type Props = {
  image: File;
  onChange: (image: Blob) => void;
};

function AvatarEditor({image, onChange}: Props): JSX.Element {
  const styles = useStyles();
  const [messageApi, contextHolder] = message.useMessage();

  const editor = useRef<ReactAvatarEditor>(null);
  const size = 200;
  const scaleStep = 0.1;
  const scaleMax = 3;
  const scaleMin = 1;
  const [scale, setScale] = useState(1.2);
  const defaultProps = {
    width: size,
    height: size,
    border: 10,
    color: [255, 255, 255, 0.6], // RGBA
    borderRadius: size / 2,
  };
  const save = useCallback(async () => {
    if (!editor.current) {
      return;
    }
    const dataUrl = editor.current.getImage().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    onChange(blob);
    messageApi.open({
      type: "success",
      content: "The image is cropped and ready to upload.",
    });
  }, [editor, onChange, messageApi]);
  return (
    <div>
      {contextHolder}
      <ReactAvatarEditor {...defaultProps} ref={editor} image={image} scale={scale} />
      <div className={styles.controlsContainer}>
        <div className={styles.smallControlsContainer}>
          <Button
            className={styles.smallButton}
            onClick={() => (scale > scaleMin ? setScale(scale - scaleStep) : undefined)}>
            -
          </Button>
          <Button
            className={styles.smallButton}
            onClick={() => (scale < scaleMax ? setScale(scale + scaleStep) : undefined)}>
            +
          </Button>
        </div>
        <Button className={styles.button} onClick={save} type="primary">
          Confirm
        </Button>
      </div>
    </div>
  );
}

export default AvatarEditor;
