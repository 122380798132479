import {Cascader} from "antd";
import React from "react";

import {WidgetProps} from "@/app/_components/form-builder";

export interface CascaderOption {
  value: string;
  label: string;
  children?: CascaderOption[];
}

interface CascaderWidgetProps extends WidgetProps {
  options: CascaderOption[];
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayRender?: (labels: any) => any;
  expandTrigger?: "click" | "hover";
}
export const CascaderWidget = ({
  options,
  value,
  placeholder,
  displayRender,
  expandTrigger = "hover",
  onChange,
}: CascaderWidgetProps) => {
  const handleChange = (selectedOptions: (string | number)[]) => {
    const selectedOption = selectedOptions.length ? selectedOptions[selectedOptions.length - 1] : null;
    if (typeof selectedOption === "string" || typeof selectedOption === "number") {
      onChange?.(selectedOption.toString());
    }
  };

  const displayRenderWithLabel = (selectedId: string[]) => {
    const traverseOptionTree = (options: CascaderOption[], id: string): string | null => {
      for (const option of options) {
        if (option.value === id) {
          return option.label;
        }

        if (option.children) {
          const label = traverseOptionTree(option.children, id);
          if (label) return label;
        }
      }

      return null;
    };
    return traverseOptionTree(options, selectedId[selectedId.length - 1]);
  };

  return (
    <Cascader
      options={options}
      value={value}
      placeholder={placeholder}
      displayRender={displayRender ?? displayRenderWithLabel}
      expandTrigger={expandTrigger}
      onChange={handleChange}
    />
  );
};
