import {Alert, Button, Form, Space} from "antd";
import React, {useMemo} from "react";
import {createUseStyles} from "react-jss";

import {FormBuilder, FormMeta} from "@/app/_components/form-builder";

import {CRUDRecord, CRUDMode, CRUDFormMetaFunction} from "./types";

import "./form.css";

type CRUDPropertiesProps<R extends CRUDRecord, F> = {
  mode: CRUDMode;
  setMode: React.Dispatch<React.SetStateAction<CRUDMode>>;
  setDeleteModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  record?: R;
  formMeta: CRUDFormMetaFunction<F>;
  onFormAccept: (values: F) => void;
  onFormCancel: () => void;
};

const useStyles = createUseStyles({
  container: {
    display: "flex",
  },
  form: {
    width: "100%",
    marginLeft: "100px",
  },
});

const CRUDProperties = <R extends CRUDRecord, F>(props: React.PropsWithChildren<CRUDPropertiesProps<R, F>>) => {
  const styles = useStyles();
  const {mode, setMode, setDeleteModalIsOpen, formMeta: formMetaFunction, record, onFormAccept, onFormCancel} = props;

  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const [error, setError] = React.useState<Error | unknown | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const isFormInViewMode = useMemo(() => ![CRUDMode.Create, CRUDMode.Update].includes(mode), [mode]);
  const isFormInCreateMode = useMemo(() => [CRUDMode.Create].includes(mode), [mode]);

  const handleFormAccept = async (values: F) => {
    setError(undefined);
    setIsLoading(true);

    try {
      await onFormAccept(values);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormCancel = async () => {
    onFormCancel();
  };

  const formMeta = formMetaFunction({mode: mode, form: form});

  const shouldWrapLabels =
    formMeta.labelWrap ??
    formMeta?.fields?.some((field) => field.label && typeof field.label === "string" && field.label.length >= 15);

  const finalFormMeta: FormMeta = {
    ...formMeta,
    initialValues: mode === CRUDMode.Create ? {...formMeta.initialValues} : {...record},
    viewMode: isFormInViewMode,
    columns: 4,
    fields: [...(formMeta?.fields ? formMeta.fields : [])],
  };

  return (
    <Space className={styles.container} direction="vertical">
      {error instanceof Error && <Alert type="error" showIcon message="Failed to load" description={error.message} />}

      <Form<F>
        labelWrap={shouldWrapLabels}
        labelAlign="right"
        className={styles.form}
        form={form}
        onValuesChange={forceUpdate}
        onFinish={handleFormAccept}>
        <FormBuilder form={form} meta={finalFormMeta} />

        {!isFormInViewMode ? (
          <Form.Item>
            <div style={{marginTop: "16px"}}>
              <Space direction="horizontal" size={20}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  style={{color: "white", backgroundColor: "#3057D1", width: "180px", fontWeight: "300"}}>
                  {isFormInCreateMode ? "Create User" : "Update User"}
                </Button>
                <Button
                  disabled={isLoading}
                  onClick={handleFormCancel}
                  style={{color: "#3057D1", borderColor: "#3057D1", width: "180px", fontWeight: "300"}}>
                  Cancel
                </Button>
              </Space>
            </div>
          </Form.Item>
        ) : (
          <div style={{marginTop: "16px"}}>
            <Space direction="horizontal" size={20}>
              <Button
                type="primary"
                onClick={() => setMode(CRUDMode.Update)}
                style={{color: "white", backgroundColor: "#3057D1", width: "180px", fontWeight: "300"}}>
                Edit User
              </Button>
              <Button
                type="text"
                disabled={isLoading}
                onClick={() => setDeleteModalIsOpen(true)}
                style={{color: "#BF1824", borderColor: "#BF1824", width: "180px", fontWeight: "300"}}>
                Delete User
              </Button>
            </Space>
          </div>
        )}
      </Form>
    </Space>
  );
};

export default CRUDProperties;
