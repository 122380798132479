type MemoizeFunc<T extends unknown[], R> = (...args: T) => R;
type ResolverFunc<T extends unknown[], K> = (...args: T) => K;

export class Memoize<T extends unknown[], R, K = T[0]> {
  static Cache: typeof Map = Map;

  private func: MemoizeFunc<T, R>;
  private resolver?: ResolverFunc<T, K>;
  private cache: Map<K, R>;

  constructor(func: MemoizeFunc<T, R>, resolver?: ResolverFunc<T, K>) {
    if (typeof func !== "function" || (resolver && typeof resolver !== "function")) {
      throw new TypeError("Expected a function");
    }

    this.func = func;
    this.resolver = resolver;
    this.cache = new Memoize.Cache<K, R>();
  }

  public call = (...args: T): R => {
    const key = this.resolver ? this.resolver(...args) : (args[0] as K);

    if (this.cache.has(key)) {
      return this.cache.get(key) as R;
    }

    const result = this.func(...args);
    this.cache.set(key, result);
    return result;
  };

  public static memoize<T extends unknown[], R, K = T[0]>(
    func: MemoizeFunc<T, R>,
    resolver?: ResolverFunc<T, K>
  ): Memoize<T, R, K> {
    return new Memoize(func, resolver);
  }
}

export default Memoize.memoize;
