"use client";

import {useQuery} from "@tanstack/react-query";
import React from "react";

import {FileCard} from "../../file-card";
import FileUploadButton from "../../file-upload-button";
import {FileModule} from "@/modules/file";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FileWidget = ({value, onChange, singleFileUpload, disableDelete}: any) => {
  const queryKey = ["file", value ?? ""];
  const fetchFiles = () => (value && typeof value === "string" ? FileModule.fetchFile({id: value}) : null);

  const {data} = useQuery({queryKey, queryFn: fetchFiles});

  const handleChange = (files: File[]) => onChange?.(files[0]);

  if (!value && !onChange) return "";

  if (value && typeof value === "string") {
    if (!data) return "Loading";

    const card = (
      <FileCard
        invalidateQueryKeyOnDelete={queryKey}
        file={data}
        status="Uploaded"
        disableDelete={disableDelete}
        handleDelete={() => onChange(null)}
      />
    );
    return card;
  }
  if (value) {
    return (
      <FileCard
        file={{file: value, name: value.name, id: "PendingFile"}}
        status="Uploading"
        handleCancel={() => onChange(null)}
      />
    );
  }
  return <FileUploadButton handleFiles={handleChange} singleFileUpload={singleFileUpload}></FileUploadButton>;
};
