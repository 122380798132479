"use client";

import {PlusOutlined} from "@ant-design/icons";
import React from "react";
import {DropzoneOptions, useDropzone} from "react-dropzone";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({token}) => ({
  addButton: {
    fontSize: "1.6rem",
  },
  addButtonDropping: {
    fontSize: "1rem",
    textAlign: "center",
  },
  container: {
    color: token.colorPrimary,
    border: "1px solid",
    minHeight: 45,
    borderColor: token.colorPrimary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type Props = {
  handleFiles: (file: File[]) => void;
  uploadButtonContent?: JSX.Element;
  singleFileUpload?: boolean;
};

function FileUploadButton({handleFiles, uploadButtonContent, singleFileUpload}: Props) {
  const styles = useStyles();

  const dropzoneOptions: DropzoneOptions = {onDrop: handleFiles, multiple: singleFileUpload ? false : true};

  const {getRootProps, getInputProps, isDragActive} = useDropzone(dropzoneOptions);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={styles.container}>
        {uploadButtonContent ?? (
          <div className={isDragActive ? styles.addButtonDropping : styles.addButton}>
            {isDragActive ? <p>Drop the files</p> : <PlusOutlined />}
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUploadButton;
