type Path = string | string[];

function has(object: Record<string, unknown>, path: Path): boolean {
  const pathArray = Array.isArray(path) ? path : path.split(".");
  let tempObject = object;

  for (let i = 0; i < pathArray.length; i++) {
    if (pathArray[i] in tempObject) {
      tempObject = tempObject[pathArray[i]] as Record<string, unknown>;
    } else {
      return false;
    }
  }

  return true;
}

export default has;
