"use client";

import {CloseOutlined} from "@ant-design/icons";
import {Drawer, Modal, notification, Space, Typography} from "antd";
import React, {useCallback, useMemo, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import CRUDProperties from "@/app/_components/crud/properties";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import {Breakpoints} from "@/utils/breakpoints";

import {CRUDRecord, CRUDMode, CRUDFormMetaFunction} from "./types";

type CRUDDetailsProps<R extends CRUDRecord, F> = {
  defaultMode: CRUDMode;
  record?: R;
  open: boolean;
  form: {
    meta: CRUDFormMetaFunction<F>;
  };
  options?: {
    actions?: {
      create?: boolean;
      update?: boolean;
      delete?: boolean;
    };
    icons?: {
      default?: (className?: string) => React.ReactNode;
    };
    labels?: {
      createTitle?: string;
      createSubtitle?: string;
      updateTitle?: string;
      updateSubtitle?: string;
    };
    modal?: {
      type?: "auto" | "drawer" | "modal";
    };
    tabs?: {
      history?: {
        enabled?: boolean;
      };
      items?: {
        key: string;
        label: string;
        children: React.ReactNode;
      }[];
    };
  };
  onCreate: (values: F) => Promise<R>;
  onUpdate: (record: R, values: F) => Promise<R>;
  onDelete: (record: R) => Promise<R>;
  onClose: () => void;
};

const useStyles = createUseStyles({
  titleIcon: {
    fontSize: 30,
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    width: 590,
  },
  dropdown: {
    width: 180,
  },
  tabs: {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    tabs: {
      "&&": {
        "& .ant-tabs-tab": {
          fontSize: 12,
          padding: "8px 0px 8px 8px !important",
        },
        "& .ant-space-item": {
          fontSize: 12,
        },
        "& .ant-typography": {
          fontSize: 12,
        },
        "& .ant-statistic-content": {
          fontSize: 14,
        },
        "& .ant-statistic-title": {
          fontSize: 12,
        },
        "& .ant-descriptions-item-label": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-tabs-content-holder": {
          overflowY: "auto",
        },
        "& .ant-descriptions-view": {
          overflowY: "auto",
        },
      },
    },
  },
  "@media (min-width: 576px) AND (max-width: 756px)": {
    tabs: {
      "&&": {
        "& .ant-descriptions-item-label": {
          fontSize: 12,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 12,
          padding: 8,
        },
      },
    },
  },
});

const CRUDDetails = <R extends CRUDRecord, F>(props: React.PropsWithChildren<CRUDDetailsProps<R, F>>) => {
  const styles = useStyles();
  const theme = useTheme();
  const {defaultMode, record, open, form, options, onCreate, onUpdate, onDelete, onClose} = props;
  const [notificationHelper] = notification.useNotification({maxCount: 1});

  const {width: windowWidth} = useWindowDimensions();
  const isWindowSmall = windowWidth <= Breakpoints.SM;

  const [mode, setMode] = useState<CRUDMode>(defaultMode);
  const [mask, setMask] = useState(false);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  let title = "";
  let subtitle: string | undefined = undefined;

  switch (mode) {
    case CRUDMode.Create:
      title = options?.labels?.createTitle ?? "New";
      subtitle = options?.labels?.createSubtitle;
      break;
    case CRUDMode.Update:
      title = options?.labels?.updateTitle ?? record?.title ?? "Details";
      subtitle = options?.labels?.updateSubtitle;
      break;
    default:
      title = record?.title ?? "Details";
      break;
  }

  const handleDeleteOk = useCallback(async () => {
    setDeleting(true);

    try {
      const _result = await onDelete(record!);
    } catch (error) {
      notificationHelper.error({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: "An error occurred while deleting the record.",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        description: error.message,
        duration: 0,
      });
    } finally {
      setDeleting(false);

      setMask(false);
      setDeleteModalIsOpen(false);
    }
  }, [notificationHelper, record, onDelete]);

  const handleDeleteCancel = useCallback(async () => {
    setMask(true);
    setMode(CRUDMode.Read);

    setDeleteModalIsOpen(false);
  }, []);

  const handleFormAccept = useCallback(
    async (values: F) => {
      let result: R | undefined = undefined;

      try {
        switch (mode) {
          case CRUDMode.Create: {
            result = await onCreate(values);
            break;
          }
          case CRUDMode.Update: {
            result = await onUpdate(record!, values);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        notificationHelper.error({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          message: `An error occurred while ${mode === CRUDMode.Create ? "creating" : "updating"} the record.`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          description: error.message,
          duration: 0,
        });
      } finally {
        if (result) {
          setMode(CRUDMode.Read);
          setMask(false);
        }
      }
    },
    [notificationHelper, mode, onCreate, onUpdate, record]
  );

  const handleFormCancel = useCallback(async () => {
    switch (mode) {
      case CRUDMode.Update:
        setMode(CRUDMode.Read);
        setMask(false);
        break;
      default:
        setMask(false);
        onClose();
        break;
    }
  }, [mode, onClose]);

  const handleCloseClick = useCallback(async () => {
    if (mode !== CRUDMode.Read) {
      handleFormCancel();
      return;
    }

    props.onClose();
  }, [mode, props, handleFormCancel]);

  const titleNode = useMemo(() => {
    const extraIconStyle = {cursor: "pointer", color: theme.isDark ? "#ffffffd9" : undefined};
    return (
      <Space style={{alignItems: "center"}}>
        <CloseOutlined style={extraIconStyle} onClick={handleCloseClick} />
        <Space direction="vertical" size={0}>
          <Typography.Text className={styles.title}>{title}</Typography.Text>
          {subtitle && (
            <Typography.Text className={styles.subtitle} type="secondary" ellipsis>
              {subtitle}
            </Typography.Text>
          )}
        </Space>
      </Space>
    );
  }, [subtitle, title, styles, handleCloseClick, theme.isDark]);

  const content = useMemo(
    () => (
      <CRUDProperties<R, F>
        key={`_properties-${mode}`}
        mode={mode}
        setMode={setMode}
        setDeleteModalIsOpen={setDeleteModalIsOpen}
        record={record}
        formMeta={form.meta}
        onFormAccept={handleFormAccept}
        onFormCancel={handleFormCancel}
      />
    ),
    [form.meta, handleFormAccept, handleFormCancel, mode, record]
  );

  const deleteModalContent = useMemo(
    () =>
      deleteModalIsOpen && (
        <Modal
          open={deleteModalIsOpen}
          title={"Delete"}
          okText={"Delete"}
          cancelText={"Cancel"}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
          okButtonProps={{danger: true, loading: deleting, color: "red"}}>
          <span>Are you sure you want to delete {record && record.title ? `'${record.title}'` : `this item`}?</span>
        </Modal>
      ),
    [deleteModalIsOpen, handleDeleteCancel, handleDeleteOk, deleting, record]
  );

  const showModal = (options?.modal?.type ?? "auto") === "auto" ? isWindowSmall : options?.modal?.type === "modal";

  return useMemo(
    () =>
      showModal ? (
        <Modal
          bodyStyle={{}}
          closable={false}
          maskClosable={mode === CRUDMode.Read}
          keyboard={mode === CRUDMode.Read}
          mask={mask}
          open={open}
          title={<Space style={{display: "flex", justifyContent: "space-between"}}>{titleNode}</Space>}
          footer={false}
          onCancel={handleCloseClick}>
          {content}
          {deleteModalContent}
        </Modal>
      ) : (
        <Drawer
          headerStyle={{paddingTop: 14, paddingBottom: 10}}
          closable={false}
          maskClosable={mode === CRUDMode.Read}
          keyboard={mode === CRUDMode.Read}
          mask={mask}
          open={open}
          placement="right"
          width={580}
          title={titleNode}
          extra={<></>}>
          {content}
          {deleteModalContent}
        </Drawer>
      ),
    [showModal, mask, open, titleNode, content, deleteModalContent, mode, handleCloseClick]
  );
};

export default CRUDDetails;
