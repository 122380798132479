import {QuestionOutlined} from "@ant-design/icons";
import {Form, Tooltip, Input} from "antd";
import React, {forwardRef} from "react";

import capitalize from "@/shared/utils/capitalize";
import {find} from "@/shared/utils/find";
import has from "@/shared/utils/has";
import pick from "@/shared/utils/pick";
import memoize from "@/utils/memoize";

const getValue = (obj, namePath) => {
  const arr = typeof namePath === "string" ? namePath.split(".") : namePath;
  let current = obj;

  for (let i = 0; i < arr.length; i += 1) {
    if (has(current, arr[i])) {
      current = current[arr[i]];
    } else {
      return undefined;
    }
  }

  return current;
};

const getWrappedComponentWithForwardRef = memoize((Comp) =>
  // eslint-disable-next-line react/display-name
  forwardRef((props, ref) => {
    return (
      <span ref={ref}>
        <Comp {...props} />
      </span>
    );
  })
);

const FormBuilderField = (props) => {
  const {field, meta, form, layout} = props;

  const label = field.tooltip ? (
    <span>
      {field.label}
      <Tooltip title={field.tooltip}>
        {" "}
        <QuestionOutlined />
      </Tooltip>
    </span>
  ) : (
    field.label
  );

  let formItemLayout = field.formItemLayout || layout || (field.label ? getValue(meta, "formItemLayout") : null);
  if (Array.isArray(formItemLayout) && formItemLayout.length >= 2) {
    formItemLayout = {
      labelCol: {span: formItemLayout[0]},
      wrapperCol: {span: formItemLayout[1]},
    };
  }
  const isFieldViewMode = meta.viewMode || field.viewMode || field.readOnly;
  const formItemProps = {
    key: field.key,
    colon: meta.colon,
    ...(meta.formItemLayout !== null ? formItemLayout : {}),
    label,
    ...pick(field, [
      "help",
      "extra",
      "labelCol",
      "wrapperCol",
      "colon",
      "htmlFor",
      "noStyle",
      "validateStatus",
      "hasFeedback",
    ]),

    ...field.formItemProps,
    className: `${meta.viewMode ? "ant-form-item-view-mode" + " ant-form-item-view-mode-v4" : ""} ${
      field.className || (field.formItemProps && field.formItemProps.className)
    }`,
  };

  if (field.key || field.name) {
    formItemProps.name = field.name || field.key.split(".");
  }
  Object.assign(formItemProps, {
    noStyle: field.noFormItem || field.noStyle,
    ...pick(field, ["shouldUpdate", "dependencies"]),
  });

  if (field.label && typeof field.label === "string") {
    formItemProps["data-label"] = field.label; // help e2e test
  }
  if (field.colSpan && formItemProps.labelCol && !field.formItemLayout) {
    const labelCol = Math.round(formItemProps.labelCol.span / field.colSpan);
    Object.assign(formItemProps, {
      labelCol: {span: labelCol},
      wrapperCol: {span: 24 - labelCol},
    });
  }

  if (field.render) {
    return field.render.call(this, {
      formItemProps,
      field,
      form,
      ...pick(props, ["disabled", "viewMode", "initialValues"]),
    });
  }

  let initialValue;
  const initialValues = meta.initialValues || {};
  if (has(field, "initialValue")) {
    initialValue = field.initialValue;
  } else if (field.getInitialValue) {
    initialValue = field.getInitialValue(field, initialValues, form);
  } else {
    initialValue = getValue(initialValues, field.name || field.key);
  }

  // Handle field props
  const rules = [...(field.rules || [])];
  if (field.required) {
    rules.unshift({required: true, message: field.message || field.requiredMessage || undefined});
  }
  const fieldProps = {
    initialValue,
    preserve: meta.preserve,
    ...pick(field, [
      "getValueFromEvent",
      "getValueProps",
      "normalize",
      "trigger",
      "preserve",
      "valuePropName",
      "validateTrigger",
      "validateFirst",
    ]),
    rules,
    ...field.fieldProps,
  };

  Object.assign(formItemProps, fieldProps);

  if (isFieldViewMode) {
    let viewEle = null;
    const formValues = form ? form.getFieldsValue(true) : {};
    let viewValue = has(formValues, field.key || field.name.join("."))
      ? getValue(formValues, formItemProps.name || field.key)
      : initialValue;
    if (field.renderView) {
      viewEle = field.renderView(viewValue, form, initialValues);
    } else if (field.viewWidget) {
      const ViewWidget = field.viewWidget;
      viewEle = <ViewWidget value={viewValue} form={form} field={field} {...field.viewWidgetProps} />;
    } else if (field.link) {
      const href = typeof field.link === "string" ? field.link : viewValue;
      viewEle = (
        <a href={href} target={field.linkTarget || "_self"}>
          {viewValue}
        </a>
      );
    } else if (field.options) {
      // a little hacky here, if a field is select/options like, auto use label for value
      const found = find(field.options, (opt) => opt[0] === viewValue);
      if (found) {
        viewValue = found[1];
      }
    }
    if (!viewEle) {
      if (typeof viewValue === "boolean") viewEle = capitalize(String(viewValue));
      else if (viewValue === undefined || viewValue === null) viewEle = "";
      else {
        viewEle = <span className="antd-form-builder-string-content">{String(viewValue) || ""}</span>;
      }
    }

    // TODO: readOnly seems to be the same with viewMode in antd v4
    if (form && field.readOnly) {
      const ele = <span className="antd-form-builder-read-only-content">{viewEle}</span>;
      return <Form.Item {...formItemProps}>{ele}</Form.Item>;
    }
    delete formItemProps.name;
    delete formItemProps.key;
    return <Form.Item {...formItemProps}>{viewEle}</Form.Item>;
  }

  // Handle widget props
  const wp = field.widgetProps || {};
  const widgetProps = {
    ...pick(field, ["placeholder", "type", "className", "class", "onChange"]),
    disabled: field.disabled || meta.disabled || props.disabled,
    required: field.required || meta.required || props.required,
    form: form,
    field: field,
    ...wp,
  };

  let FieldWidget = field.widget || Input;

  if (field.forwardRef) {
    FieldWidget = getWrappedComponentWithForwardRef(FieldWidget);
  }
  const valueProps = {};
  const ele = (
    <FieldWidget {...widgetProps} {...valueProps}>
      {field.children || null}
    </FieldWidget>
  );

  return <Form.Item {...formItemProps}>{ele}</Form.Item>;
};

export default FormBuilderField;
