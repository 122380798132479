"use client";

import React, {useState} from "react";

import AvatarEditor from "../../avatar-editor";
import FileUploadButton from "../../file-upload-button";
import Image from "../../image";
import {isNil} from "@/shared/utils/nil";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ImageWidget = ({value, onChange}: any) => {
  const [originalImage, setOriginalImage] = useState<File | undefined>();
  const handleChange = (files: File[]) => {
    onChange(files[0]);
    setOriginalImage(files[0]);
  };

  if (isNil(value) && isNil(onChange)) return "";

  if (originalImage) {
    return <AvatarEditor image={originalImage} onChange={onChange} />;
  }

  if (isNil(value)) {
    if (!isNil(onChange)) {
      return <FileUploadButton handleFiles={handleChange} singleFileUpload={true}></FileUploadButton>;
    }
    return <></>;
  }
  if (isNil(onChange)) {
    return <Image data={value} round alt="Corrupted Image" />;
  }
  return (
    <FileUploadButton
      uploadButtonContent={<Image data={value} round preview={false} alt="Corrupted Image" />}
      handleFiles={handleChange}
      singleFileUpload={true}
    />
  );
};
