import {authorizedClientRequest} from "@/api/csr-request";
import {
  CreateUserDocument,
  CreateUserMutationVariables,
  DeleteUserDocument,
  DeleteUserMutationVariables,
  UpdatePasswordDocument,
  UpdatePasswordMutationVariables,
  UpdateUserDocument,
  UpdateUserMutationVariables,
  UsersDocument,
  UsersQueryVariables,
} from "@/shared/codegen/types";

async function fetchUsers(variables: UsersQueryVariables = {}) {
  const response = await authorizedClientRequest({
    document: UsersDocument,
    variables,
  });

  if (response.users) return response.users;

  throw new Error("couldn't fetch users");
}

async function createUser(variables: CreateUserMutationVariables) {
  const response = await authorizedClientRequest({
    document: CreateUserDocument,
    variables,
  });
  if (response.createUser) return response.createUser;

  throw new Error("couldn't create user");
}

async function updateUser(variables: UpdateUserMutationVariables) {
  const response = await authorizedClientRequest({
    document: UpdateUserDocument,
    variables,
  });
  if (response.updateUser) return response.updateUser;

  throw new Error("couldn't update user");
}

async function deleteUser(variables: DeleteUserMutationVariables) {
  const response = await authorizedClientRequest({
    document: DeleteUserDocument,
    variables,
  });
  if (response.deleteUser) return response.deleteUser;

  throw new Error("couldn't delete user");
}

async function updatePassword(variables: UpdatePasswordMutationVariables) {
  try {
    await authorizedClientRequest({
      document: UpdatePasswordDocument,
      variables,
    });
  } catch {
    throw new Error("couldn't update password");
  }
}

export const UserModule = {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  updatePassword,
};
