import {Button, Checkbox, Col, FormInstance, Input, InputNumber, Radio, Row, Select, Switch} from "antd";
import {Rule} from "rc-field-form/lib/interface";
import React from "react";

import {CascaderWidget} from "@/app/_components/form-builder/widgets/cascader-widget";
import {ColorWidget} from "@/app/_components/form-builder/widgets/color-widget";
import {FileWidget} from "@/app/_components/form-builder/widgets/file-widget";
import {ImageWidget} from "@/app/_components/form-builder/widgets/image-widget";
import {UserWidget} from "@/app/_components/form-builder/widgets/user-widget";
import {find} from "@/shared/utils/find";
import {isPlainObject} from "@/shared/utils/is-plain-object";

import FormBuilderField from "./form-builder-field";
import "./form-builder.css";
import {DateWidget} from "./widgets/date-widget";
import {TableWidget} from "./widgets/table-widget";

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface WidgetProps<T = any> {
  value: T;
  form?: FormInstance<T>;
  field?: FormMetaField;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: T) => void;
}

export type WidgetType =
  | string
  | React.ReactComponentElement<any>
  | ((params: {value: any}) => string | React.ReactComponentElement<any> | null)
  | null;

export type FormItemLayoutType = {
  labelCol?: any;
  wrapperCol?: any;
};

export type RenderFunction = (value: any, form: FormInstance<any>, initialValues: any) => React.ReactNode;

export type FieldOption = {
  label: string | React.ReactElement;
  value: any;
  disabled?: boolean;
};

export type FormMetaField = {
  /** Required. The field key. Could be nested like user.name.last.
      It's just the key value passed to getFieldDecorator(key, options) */
  key: string;

  /** Alternative of key. In form v4, if you need nested property for collected form values like:
      { name: { first, last } } you can define an array for the name property: ['name', 'first'].
      If you prefer name.first, use key to define it. */
  name?: string | string[];

  /** Label text. */
  label?: string | React.ReactElement;

  /** Whether the field is in view mode. Note if a field is in viewMode but FormBuilder is not,
      the label in the field is still right aligned. */
  viewMode?: boolean;

  /** Whether the field is in view mode. Note if a field is in viewMode but FormBuilder is not,
      the label in the field is still right aligned. */
  readOnly?: boolean;

  /** If set, there is a question mark icon besides label to show the tooltip. */
  tooltip?: string | React.ReactNode;

  /** Which component used to render field for editing. The component should be able to be managed by antd form. */
  widget?: WidgetType;

  /** Props passed to widget. */
  widgetProps?: any;

  /** Which component used to render field in view mode. */
  viewWidget?: WidgetType;

  /** Props passed to viewWidget */
  viewWidgetProps?: any;

  /** This applies formItemLayout only to this field rather than which defined in the root meta. */
  formItemLayout?: FormItemLayoutType | FormItemLayoutType[];

  /** If provided, this is used for rendering the whole field in both edit and view mode, should render <Form.Item>,
      getFieldDecorator itself. widget property will be ignored. */
  render?: RenderFunction;

  /** If provided, this is used for rendering field value in view mode, viewWidget will be ignored. */
  renderView?: RenderFunction;

  /** How many columns the field should take up. */
  colSpan?: number;

  /** The initialValue to be passed to the field widget. In view mode, it's the value to be display. */
  initialValue?: any;

  /** Get the initialValue of the field. This may be used to combine multiple fields into one field */
  getInitialValue?: (field: FormMetaField, initialValues: any, form: FormInstance<any>) => any;

  /** If set to true, every widget in field will be given a disabled property regardless of if it's supported. */
  disabled?: boolean;

  /** In multiple columns layout, used to clear left, right or both side fields. Like the clear property in css.
      Could be left: the field starts from a new row; right: no fields behind the field;
      both: no other fields in the same row. */
  clear?: "left" | "right" | "both";

  /** If your field widget is a funcional component which doesn't implement forwardRef,
      set this to true so that React doesn't prompt warning message. */
  forwardRef?: boolean;

  /** By default, each field is wrapped with <Form.Item>, if set to true, it just use getFieldDecorators. */
  noFormItem?: boolean;

  /** The same with old noFormItem. Provide the alias noStyle to be consistent with antd v4. */
  noStyle?: boolean;

  /** The children of widget defined in meta. */
  children?: React.ReactNode;

  /** Whether the field is required. */
  required?: boolean;

  /** If a field is required, you can define what message provided if no input.
      By default, it's ${field.label} is required. */
  message?: string;

  /** Only used by select, radio-group. checkbox-group components. */
  options?: string[] | FieldOption[];

  /** The props passed to <Form.Item>. Below properties are short way to pass props to <Form.Item>.
      See more from antd's doc */
  formItemProps?: any;

  /** Used with label, whether to display : after label text. */
  colon?: boolean;

  /** The extra prompt message. It is similar to help.
      Usage example: to display error message and prompt message at the same time. */
  extra?: string | React.ReactNode;

  /** Used with validateStatus, this option specifies the validation status icon.
      Recommended to be used only with Input. */
  hasFeedback?: boolean;

  /** The prompt message. If not provided, the prompt message will be generated by the validation rule. */
  help?: string | React.ReactNode;

  /** Set sub label htmlFor. */
  htmlFor?: string;

  /** The layout of label.
      You can set span offset to something like {span: 3, offset: 12} or
      sm: {span: 3, offset: 12} same as with <Col>. */
  labelCol?: any;

  /** The validation status.
      If not provided, it will be generated by validation rule. options: 'success' 'warning' 'error' 'validating' */
  validateStatus?: string;

  /** The layout for input controls, same as labelCol. */
  wrapperCol?: any;

  /** The options to pass to getFieldDecorator(id, options).
      Below properties are short way to pass options to getFieldDecorator(id, options). See more from antd's doc */
  fieldProps?: any;

  /** Specify how to get value from event or other onChange arguments */
  getValueFromEvent?: (...args: any[]) => any;

  /** Get the component props according to field value. */
  getValueProps?: (value: any) => any;

  /** Normalize value to form component */
  normalize?: (value: any, prevValue: any, allValues: any) => any;

  /** Keep the field even if field removed. */
  preserve?: string;

  /** Includes validation rules. Please refer to "Validation Rules" part for details. */
  rules?: Rule[];

  /** When to collect the value of children node */
  trigger?: string;

  /** Whether stop validate on first rule of error for this field. */
  validateFirst?: boolean;

  /** When to validate the value of children node. */
  validateTrigger?: string | string[];

  /** Props of children node, for example, the prop of Switch is 'checked'. */
  valuePropName?: string;

  placeholder?: string;
  dynamic?: boolean;
};

export type MetaConvertor = (field: FormMetaField) => FormMetaField;

export type FormMeta = {
  columns?: number;
  formItemLayout?: FormItemLayoutType | FormItemLayoutType[];
  viewMode?: boolean;
  labelWrap?: boolean;
  disabled?: boolean;
  initialValues?: any;
  fields?: FormMetaField[];
  gutter?: number;
};

const widgetMap: Record<string, any> = {};

function getWidget(widget: any) {
  if (!widget) return null;
  if (typeof widget === "string") {
    if (!widgetMap[widget] || !widgetMap[widget].widget) {
      throw new Error(`Widget '${widget}' not found, did you defined it by FormBuilder.defineComponent?`);
    }
    return widgetMap[widget].widget;
  }
  return widget;
}

function normalizeMeta(meta: any) {
  let fields = Array.isArray(meta) ? meta : meta.fields || meta.elements;

  if (!fields) fields = [meta];

  fields = fields.map((field: FormMetaField) => {
    const widget = getWidget(field.widget);
    const viewWidget = getWidget(field.viewWidget);
    const dynamic = field.dynamic !== false;
    // Find metaConvertor
    const item = find(
      Object.values(widgetMap),
      (entry) => (entry.widget === widget || entry.widget === viewWidget) && entry.metaConvertor
    );
    if (item) {
      const newField = item.metaConvertor(field);
      if (!newField) {
        throw new Error(`metaConvertor of '${String(field.widget)}' must return a field`);
      }
      return {...newField, viewWidget, widget, dynamic};
    }
    return {...field, widget, viewWidget, dynamic};
  });

  if (Array.isArray(meta) || (!meta.fields && !meta.elements)) {
    return {fields};
  }

  return {
    ...meta,
    fields,
  };
}

export type FormBuilderProps = {
  meta: FormMeta;
  form: FormInstance<any>;
  viewMode?: boolean;
  initialValues?: any;
  disabled?: boolean;

  getMeta?: (form: FormInstance<any>, props: FormBuilderProps) => FormMeta;
};

const FormBuilderInner: React.FC<FormBuilderProps> = (props) => {
  const {meta, viewMode, initialValues, disabled = false, form = null} = props;
  if (!meta) return null;

  const newMeta = normalizeMeta(meta);
  newMeta.viewMode = newMeta.viewMode || viewMode;
  newMeta.initialValues = newMeta.initialValues || initialValues;
  const {fields, columns = 1, gutter = 10} = newMeta;

  const hasExtendedLabelField = newMeta.fields.some(
    (field: {label: string | any[]}) => field.label && field.label.length >= 10
  );
  const hasCustomLayout = newMeta.fields.some((field: FormMetaField) => field.formItemLayout);
  const formLayoutStyle = hasCustomLayout ? null : hasExtendedLabelField ? [16, 8] : [8, 16];

  const elements = fields.map((field: FormMetaField) => (
    <FormBuilderField
      key={field.key}
      field={field}
      layout={formLayoutStyle}
      disabled={disabled}
      meta={newMeta}
      form={form}
    />
  ));

  if (columns === 1) {
    return elements;
  }

  const rows = [];
  // for each column, how many grid cols
  const spanUnit = 24 / columns;

  for (let i = 0; i < elements.length; ) {
    const cols = [];
    for (
      let j = 0;
      (j < columns || j === 0) && // total col span is less than columns
      i < elements.length && // element exist
      (!["left", "both"].includes(fields[i].clear) || j === 0); // field doesn't need to start a new row

    ) {
      const fieldSpan = fields[i].colSpan || 1;
      cols.push(
        <Col key={j} span={Math.min(24, spanUnit * fieldSpan)}>
          {elements[i]}
        </Col>
      );
      j += fieldSpan;
      if (["both", "right"].includes(fields[i].clear)) {
        i += 1;
        break;
      }
      i += 1;
    }
    rows.push(
      <Row key={i} gutter={gutter}>
        {cols}
      </Row>
    );
  }
  return rows;
};

export const FormBuilder: React.FC<FormBuilderProps> & {
  defineWidget: (
    key: string,
    component: React.ReactComponentElement<any>,
    metaConvertor?: (field: FormMetaField) => FormMetaField
  ) => void;
  useForceUpdate: () => any;
} = (props) => {
  const {getMeta, form} = props;
  const meta = getMeta ? getMeta(form, props) : props.meta;
  return <FormBuilderInner {...props} form={form ?? null} meta={meta} />;
};

FormBuilder.defineWidget = (
  name: string,
  widget: string | React.ReactComponentElement<any>,
  metaConvertor: MetaConvertor | null = null
) => {
  if (widgetMap[name]) throw new Error(`Widget "${name}" already defined.`);

  widgetMap[name] = {
    widget,
    metaConvertor,
  };
};

FormBuilder.useForceUpdate = () => {
  const [, updateState] = React.useState();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const forceUpdate = React.useCallback<any>(() => updateState({}), []);
  return forceUpdate;
};

/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-ignore
const mapOptions = (options) => {
  if (!Array.isArray(options)) {
    throw new Error("Options should be array in form builder meta.");
  }
  return options.map((opt) => {
    if (Array.isArray(opt)) {
      return {value: opt[0], label: opt[1]};
    } else if (isPlainObject(opt)) {
      return opt;
    } else {
      return {value: opt, label: opt};
    }
  });
};

// @ts-ignore
FormBuilder.defineWidget("checkbox", Checkbox, (field) => {
  return {...field, valuePropName: "checked"};
});

// @ts-ignore
FormBuilder.defineWidget("switch", Switch, (field) => {
  return {...field, valuePropName: "checked"};
});

// @ts-ignore
FormBuilder.defineWidget("button", Button);
// @ts-ignore
FormBuilder.defineWidget("input", Input);
// @ts-ignore
FormBuilder.defineWidget("password", Input.Password);
// @ts-ignore
FormBuilder.defineWidget("textarea", Input.TextArea);
// @ts-ignore
FormBuilder.defineWidget("number", InputNumber);
// @ts-ignore
FormBuilder.defineWidget("date-picker", DateWidget);
// @ts-ignore
FormBuilder.defineWidget("radio", Radio);
// @ts-ignore
FormBuilder.defineWidget("radio-group", Radio.Group, (field) => {
  // @ts-ignore
  const RadioComp = field.buttonGroup ? Radio.Button : Radio;
  if (field.options && !field.children) {
    return {
      ...field,
      widgetProps: {
        ...field.widgetProps,
        name: field.key,
      },
      children: mapOptions(field.options).map((opt) => (
        <RadioComp value={opt.value} key={opt.value}>
          {opt.label}
        </RadioComp>
      )),
    };
  }
  return field;
});
// @ts-ignore
FormBuilder.defineWidget("checkbox-group", Checkbox.Group, (field) => {
  if (field.options && !field.children) {
    return {
      ...field,
      children: mapOptions(field.options).map((opt) => (
        <Checkbox value={opt.value} key={opt.value}>
          {opt.label}
        </Checkbox>
      )),
    };
  }
  return field;
});
// @ts-ignore
FormBuilder.defineWidget("select", Select, (field) => {
  if (field.options && !field.children) {
    return {
      ...field,
      children: mapOptions(field.options).map((opt) => (
        <Select.Option label={opt.label} value={opt.value} key={opt.value} disabled={opt.disabled}>
          {opt.children || opt.label}
        </Select.Option>
      )),
    };
  }
  return field;
});

// region Custom widgets

// @ts-ignore
FormBuilder.defineWidget("cascader", CascaderWidget);
// @ts-ignore
FormBuilder.defineWidget("color", ColorWidget);
// @ts-ignore
FormBuilder.defineWidget("user", UserWidget);
// @ts-ignore
FormBuilder.defineWidget("file", FileWidget);
// @ts-ignore
FormBuilder.defineWidget("image", ImageWidget);
// @ts-ignore
FormBuilder.defineWidget("table", TableWidget);

// endregion

/* eslint-enable @typescript-eslint/ban-ts-comment */

/* eslint-enable @typescript-eslint/no-explicit-any */
