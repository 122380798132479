import {Typography} from "antd";
import {format} from "date-fns";
import React from "react";

import DatePicker from "@/app/_components/date-picker";
import type {WidgetProps} from "@/app/_components/form-builder";

interface DateWidgetProps extends WidgetProps<Date | null> {}

export const DateWidget = ({onChange, value, ...datePickerProps}: DateWidgetProps) => {
  const renderDate = (date: Date | null) => {
    return date ? format(date, "MM/dd/yy") : "";
  };

  const handleDateChange = (date: Date | null, _dates: string | string[]) => {
    if (onChange) {
      onChange(date);
    }
  };

  return !onChange ? (
    <Typography.Text>{renderDate(value)}</Typography.Text>
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DatePicker {...datePickerProps} onChange={handleDateChange} value={value} />
  );
};
